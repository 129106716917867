import React from 'react'
import './footer.css'
// import {FaFacebookSquare} from '../../../assets/fontawesome/css'
// import {FaTwitterSquare} from 'react-icons/fa'

const Footer = () => {
  return (
    <footer>
        <div className="footer__container" id='footer_container'>
            <section className='footer__info'>
                <div className="title__address">
                    <h2>UTC Finance </h2> 

                    <h3>UTC Finance Pvt Ltd, <br />
                        2nd Floor, Plot no. 89-90, Rajendra Park <br />
                        Near Toyota Showroom, Sec-17, Gurugram</h3>

                    {/* <a href=""><FaFacebookSquare/></a>
                    <a href=""><FaTwitterSquare/></a>  */}

                    <p>&copy; All rights reserved by UTC Finance</p>
                </div>
                <div className="extra__links">
                    <div className="company">
                        <h4>Company</h4>
                        <p>
                            <a>About Us</a> <br />
                            <a href="https://www.freeprivacypolicy.com/live/780fcc8d-4530-4313-bbe9-a693350afa6a" target='blank'>Privacy Policy</a> <br />
                            <a href="https://www.freeprivacypolicy.com/live/14f1c711-8e34-4f71-b058-3a8c40b7a8f9" target='blank'>Terms and Condition</a> <br />
                        </p>
                    </div>
                    <div className="contact">
                        <h4>Let's Talk</h4>
                        <p>
                            <a>Have any Doubts ?</a> <br />
                            <a>Feel free to</a> <br />
                            <a href="tel:888XXXXXXX">Call Us on: +91 708XXXXXXX</a> <br />
                            <a href='mailto:hello@usethecred.com?subject=Me&body=Hello!'>Mail Us on: hello@usethecred.com</a> <br />

                        </p>
                    </div>
                </div>
             
            </section>

            <div className="horizontal-line"></div>

            <section className='footer__credits'>
                <p>UTC Finance is a platform that connects lender with RBI-licensed NBFC partners and banks. The loans offered on the platform are subject to the terms and conditions and loan approval process of the Bank and NBFC partners</p>
            </section>
            </div>

            
    </footer>
  )
}

export default Footer
import React from 'react'
import Hero from './components/hero/Hero'
import Footer from './components/footer/Footer'
import Check from './components/checkScore/Check'
import Products from './components/products/Products'
import Faquestions from './components/faquestions/Faquestions'
import Achievements from './components/achievements/Achievements'
import Details from './components/details/Details'
import Reasons from './components/reasons/Reasons'
import Emicalculator from './components/emicalculator/Emicalculator'
// import Form from './components/form/Form'
// import ButtonComponent from './components/dialog_box/ButtonComponent'
import Quickcontact from './components/quickcontact/Quickcontact'
// import MultiStepForm from './components/multistep form/MultiStepForm'
// import Mui from './components/mui_form/Mui'
import CustReview from './components/cust_review/CustReview.jsx'


const App = () => {
  return (
    <>
        <Hero/>
        {/* <Form/> */}
        {/* <ButtonComponent/> */}
        <Products/>
        {/* <Mui/> */}
        {/* <MultiStepForm/> */}
        <Emicalculator/>
        <Check/>
        <Details/>
        <Reasons/>
        <Faquestions/>
        <Achievements/>
        <CustReview/>
        <Quickcontact/>
        <Footer/>
    </>
  )
}

export default App
import React from 'react'
import './check.css'
import png from '../../assets/credit_bg.png'
import pdf from '../../assets/guidlines.pdf'

function Check() {
  return (
    <section className='check_score'>
        <div className="display">
            <img src={png} alt="Credit pic" />
        </div>
        <div className="content">
            <h1>Striving for excellent credit?</h1>
            <p>Unlock your path to excellent credit with our guidelines, providing proven strategies for mastering timely payments and achieving top credit scores effortlessly.</p>
            <a href={pdf} target='blank' className='trybtn'>View Guidelines</a>
        </div>
    </section>
  )
}

export default Check
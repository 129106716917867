import React from 'react'
import './Hero.css'
import Navbar from '../navbar/Navbar'
import Opening from '../opening/Opening.jsx'

const Hero = () => {
  return (
    <div className='hero_img'>
        <Navbar/>
        <Opening/>
    </div>
  )
}

export default Hero
import React from "react";
import Faq from "react-faq-component";
import './faquestions.css'

const data = {
    // title: "FAQ (How it works)",
    rows: [
        {
            title: "What is the definition of a small loan?",
            content: ` Small loans are usually for amounts ranging from a few hundred to a few thousand dollars and have shorter repayment terms compared to larger loans.`,
        },
        {
            title: "What is the least loan that I am eligible for?",
            content: `The minimum amount of loan that you can borrow will depend on the lender and their policies. Some lenders may have a minimum loan amount requirement, while others may be willing to lend smaller amounts. It is recommended to contact advisors to inquire about your minimum loan amount options.`,
        },
        {
            title: "I have zero credit score. Will you give me a personal loan?",
            content: `Yes. Most of the banks and NBFCs require a credit score of 750+ to approve your loan. However, we understand that everybody begins somewhere. And we would love to be that 'somewhere' where you begin building a good credit.`,
        },
        {
            title: "Are there any hidden fees?",
            content: `No. There are no hidden fees and charges involved while taking a loan from UTC Finance. All the fees and charges like interest rates, loan processing fees, late payment charges and foreclosure charges are mentioned in the loan agreement.`,
        },
        {
            title: "How much processing fees do you charge?",
            content: `It varies by lender and can range upto 5% of the loan amount or a flat fee. It is important to review the terms and conditions of the loan agreement to understand any fees associated with the loan.`,
        },
        {
            title: "What happens if I miss a payment or default on the loan??",
            content:"If you miss a payment or default on the loan, there may be late fees, penalties, and it could negatively impact the credit score. Contact the lender and discuss options for resolving the issue.",
        },
        
        
        
    ],
};

const styles = {
    titleTextColor: "blue",
    rowTitleColor: "black",
    rowContentColor: "var(--color-grey)",
    arrowColor: "var(--color-primary)",
    rowContentAlign: "Left"
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};

const Faquestions = () => {

    return (
        <section className="faq__container" id="faq_container">
            <h1>Frequently asked Questions</h1>
            <p>Now get a loan on your terms with our flexible EMI options and take control of what & when you pay</p>
            <Faq className='faq__data'
                data={data}
                styles={styles}
                config={config}
            />
        </section>
    );
}

export default Faquestions
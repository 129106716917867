import React from 'react'
import AwesomeTestimonial from 'react-awesome-testimonials';
import './CustReview.css'
import one from '../../assets/one.jpg'
import two from '../../assets/two.jpg'
import three from '../../assets/three.jpg'
import four from '../../assets/four.jpg'
import five from '../../assets/five.jpeg'
import six from '../../assets/six.jpg'
import seven from '../../assets/seven.jpg'
import eight from '../../assets/eight.jpg'
import nine from '../../assets/nine.jpg'

function CustReview() {
  return (
<>
    <h1 className='heading_review'>Our Customers Love What We Do </h1>
    <p className='para_review'>Our customers love our service! Read their reviews to discover why they're raving about <br/> our quality, service , and overall experience. Join the satisfied ranks today!</p>
    <AwesomeTestimonial
    testimonials={[
      {
        name: "Riya Yadav",
        company: "Delhi",
        img_src: one,
        review:
          "Great experience. My credit score is very less. No body gave me loan but this app gave me loan for 1800. Yes the amount is less but this is a great app to build your credit score and avail higher amount of loan by doing timely payment. I will do timely payment and improve my credit score. Thanks UTC Finance👍👍👍👍😊😊 ",
      },
      {
        name: "Radhika Gupta",
        company: "Noida",
        img_src: two,
        review:
          "Amazing App. It's a very trustworthy and genuine app. It's very easy to get a loan and this app can be easily trusted. The customer service is excellent very polite and helpful. The loan amount is disbursed within 24 after submitting the application. Its one of the few genuine loan app's found. You all should give it a try.",
      },
      {
        name: "Prathvi Raj",
        company: "Delhi",
        img_src: five,
        review:
          "UTC Finance is very very nicely. i liked it . fast and 100 % secured , very essential when you are in some emergency situations, progress is very neat and easy. Processing also matter of seconds, I'm impressed with their service.Good and Best Place.",
      },
      {
        name: "Jitendra Singh",
        company: "Gurugram",
        img_src: six,
        review:
          "High service charges. One loan but service fee& GST charges 2 times if 2 EMIs if 3 EMls charged 3 times service fee & GST because of this taking loan from UTC Finance is very costly. Money disbursement very fast. Customer service is good. Please reduce service fee and get five star ⭐✨.",
      },
      {
        name: "Pritam Kinha",
        company: "Gurugram",
        img_src: seven,
        review:
          "Good service and for emergency need but processing fee is very very high like too much they make money in processing fee only. Need to make processing fee is too good for customers. That's it..",
      },
      {
        name: "Aashish Gupta",
        company: "Gurugram",
        img_src: eight,
        review:
          "Found the personal loan EMI calculator on UTC Finance’s website to be very efficient and simple to use.",
      },
      {
        name: "Sahil Bhardwaj",
        company: "Gurugram",
        img_src: nine,
        review:
          "I would like to thank UTC Finance for the timely help! Very good personal loan app, easy to access and good customer service!",
      },
      {
        name: "Priya Rathi",
        company: "Delhi",
        img_src: three,
        review:
          "It is quite true to call an APP fake if the loan does not get approved. However, for me, UTC Finance has been a blessing in disguise. Thanks, UTC Finance Team. Appreciate your prompt service and the loan you have provided.",
      },
      {
        name: "Nidhi Yadav",
        company: "Noida",
        img_src: four,
        review:
          "I was planning on taking a loan from a quick loan and am glad to have been recommended the UTC Finance website by my friend.",
      },
    ]}
  />
  </>
  )
}

export default CustReview
import React from 'react'
import './navbar.css'
// import { Link } from 'react-scroll';
import { useEffect, useState } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import logo from '../../assets/logo copy.png'

const Navbar = () => {
  
    const [scrolling, setScrolling] = useState(false);
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }}
  return (
    <nav className={scrolling ? 'navbar-scroll' : ''}>
        <div className="navbar__logo">
            <img src={logo} alt="logo" />
        </div> 
        <section className='navbar'>
            <Link activeClass="active" to="opening_container" spy={true} smooth={true} duration={500}>
              <span>Home</span>
            </Link>
            <Link to="products_container" spy={true} smooth={true} duration={200}>
              <span>Products</span>
            </Link>
            <Link to="footer_container" spy={true} smooth={true} duration={500}>
              <span>Contact</span>
            </Link>
            <Link to="faq_container" spy={true} smooth={true} duration={500}>
              <span>FAQ</span>
            </Link>
            
        </section>
          <a href="login" className='btn btn-primary'> Apply Now</a>
    </nav>
  )
}

export default Navbar
import React from 'react'
import './products.css'
import business from '../../assets/innovation.png'
import home from '../../assets/house.png'
import car from '../../assets/protection.png'
import personal from '../../assets/man.png'

function Products() {
  return (
    <section className='products' id='products_container'>
        <h1 className='products_title'> Our Products </h1>
        <p>Our Advisors makes borrowing stress-free. Get the money you need for your goals without any hassle with our simple and convenient process.</p>
        <div class="container">
            <div class="card personal">
                <div class="imgBx">
                    <img src={personal} alt="Personal_loan" />
                </div>

                <div class="contentBx">
                    <h2>Personal Loan</h2>
                    <div class="details">
                        <h3>get personal loan credited to your account within 24 hrs.</h3>
                    </div>
                </div>
            </div>
            <div class="card business">
                <div class="imgBx">
                    <img src={business} alt="Business-loan"/>
                </div>

                <div class="contentBx">
                    <h2>Business Loan</h2>
                    <div class="details">
                        <h3>business loans at low intrest rates ready to be dispossed</h3>
                    </div>
                </div>
            </div>
            <div class="card home">
                <div class="imgBx">
                    <img src={home} alt="Home-loan"/>
                </div>

                <div class="contentBx">
                    <h2>Home Loan</h2>
                    <div class="details">
                        <h3>home loan with easy emi and fast approval</h3>
                    </div>
                </div>
            </div>
            <div class="card vehical">
                <div class="imgBx">
                    <img src={car} alt='Car Loan'/>
                </div>

                <div class="contentBx">
                    <h2>Vehicle Loan</h2>
                    <div class="details">
                        <h3>instant approval for 2-wheeler and 4-wheeler vehicles</h3>
                    </div>
                </div>
            </div>
         </div>
</section>
  )
}

export default Products